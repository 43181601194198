export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: false,
  apiUrl: 'https://ecmsapi.fhc.gov.ng/api',
  courtTitle: 'FEDERAL HIGH COURT OF NIGERIA',
  courtTitleSentence: 'Federal High Court of Nigeria',
  stateTitle: 'NIGERIA',
  stateTitleSentence: 'Nigeria',
  currentDivision: 'Abuja Judicial Division',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsapi.fhc.gov.ng/api',
  BASE_URL_II: 'https://ecmsapi.fhc.gov.ng/api',
  API_URL: '/',
  PORTAL_URL: 'https://portal.fhc.gov.ng',
  currentState: '',
  court_bg_path: 'assets/img/court-bg.jpg',
  court_logo_path: 'assets/img/fhc-logo.png',
  paystack_public_key: 'pk_live_bc914d50fe669858c511bb882914d225532b05b9',
  remitta_api_key: 'RkhDfDEzOTMwMjY1MzYyfDczOTdmMTIyZTJiYWFhNDUxYTUwNjJmOTkxYTBmOTlhOWJiOGY2OGRmMDVhNWE1MTYyZmI5MjRlYTllYTE1ZjcyYzQzMDRiNTJmMDhlMTdiNWY0ZGY1NDgyNTE0MjRlNTI5NWZiM2UxZDZmNzBjM2E5ZDQ0ZWFiOTg4ZGFiNTA4',
  remitta_mode: 'live',
  // remitta_api_key: 'QzAwMDAyNTE5MzV8NTcwODYyNDl8NTk0ZGFkZTE2OWM3N2UyZTMyMzlhYWJiZjM4NjdiZThhYWQyMTUxMWVlMjNiMjdjYmFmODVlNTZlMDFlNTA5ZGZjNDVhOTliM2Q2OGNhZmE0YmI3YzllODBhMTdmNmIxMDc4ZWRlMWM5MDc1OTc4ZGYxMDQ5ZjIxYWU2Mjc0YjQ=',
  // remitta_mode: 'test',
  // interswitch_mechant_code: 'VNA',
  interswitch_mechant_code: 'MX128354',
  // interswitch_pay_item: '103',
  interswitch_pay_item: 'Default_Payable_MX128354',
  interswitch_mode: 'LIVE',
  currentCourtInfoEmail: 'info@fhc.gov.ng',
  // EXTRAS
  showHelpLine: false,
  helpLine: "09137146567, 07073735972",
  showJudiciarySuffix: false,
  showState: false,
  showDivision: true,
  showSupCourtTitle: false,
  supCourtTitle:'The Judiciary,',
  supCourtTitleColor:'red',
  showAffidavitFee: false
};
